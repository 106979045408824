import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
import media from '../utils/media';

const GlobalStyles = createGlobalStyle`
  :root {
    font-size: 11px;
  }

  body {
    font-family: 'Source Sans Pro', sans-serif;
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: rgba(0, 0, 0, 0.8);
    min-height: 100vh;
    position: relative;
    font-size: 1.6rem;
    background-color: #FAF9F7;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
    line-height: 1.13;
    color: #423D33;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2.4rem;
  }

  h4 {
    font-size: 1.6rem;
  }
  
  code {
    font-family: Menlo,Monaco,"Courier New",Courier,monospace;
    word-break: break-word;
    font-size: 1.6rem;
  }
  
  .gatsby-highlight {
    box-shadow: 0 4px 6px 0 #D3CEC4;
  }

  .gatsby-highlight, .gatsby-highlight-code-line {
    ${media.tablet`
      margin-right: -1.3rem;
      margin-left: -1.3rem;
    `}
    }

  pre code {
    word-break: normal;
  }

  :not(pre) > code[class*="language-"], pre[class*="language-text"] {
    background-color: #423D33;
    color: #FAF9F7;
    font-size: 1.3rem;
    padding: 0 4px;
  }
`;

const Footer = styled.footer`
  display: block;
  height: 6rem;
`;

const Content = styled.div`  
  max-width: 700px;
  margin: 0 auto;

  ${media.tablet`
    padding: 2.6rem 1.3rem;
  `}
`;

const TopLine = styled.div`
  background-color: #610404;
  height: 8px;
  box-shadow: 0 4px 12px 0 #D3CEC4;
`

class Layout extends Component {
  render() {
    const { children, showHeader } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            { showHeader ? <Header title={data.site.siteMetadata.title} /> 
              : <TopLine/>}
            <Content>{children}</Content>
            <Footer />
            <GlobalStyles />
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showHeader: PropTypes.bool.isRequired,
};

export default Layout;
