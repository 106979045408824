import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 40px;

  .subscribe-wrapper {
    border-top: 3px solid #14919B;
    display: flex;
    background-color: white;
    border-radius: 2px 2px 5px 5px;
    box-shadow: 0 2px 12px 0 #D3CEC4;
  }
  @media (max-width: 630px) {
    .subscribe-wrapper {
      display: block;
    }
  }

  .subscribe-wrapper .thanks {
    padding: 20px 50px;
  }
  .subscribe-wrapper .information {
    flex: 1;
    background-color: #f3f2f2;
    border-radius: 2px 0 0 5px;
    padding: 20px 50px;
  }
  .subscribe-wrapper .title {
    font-weight: 900;
    font-size: 18px;
  }
  .subscribe-wrapper .form {
    flex: 1;
    padding: 20px 50px;
    border-radius: 5px;
  }
  .subscribe-wrapper .form button {
    color: white;
    background-color: #54D1DB;
    padding: 12px 30px;
    margin-bottom: 16px;
    border-radius: 12px;
    font-weight: 700;
    cursor: pointer;
    border: 0;
  }
  .subscribe-wrapper .form input {
    color: #555555;
    font-size: 15px;
    width: 100%;
    padding: 12px;
    margin-bottom: 16px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }
  .subscribe-wrapper .icon {
    font-size: 55px;
    text-align: center;
  }
  .subscribe-wrapper .no-spam {
    font-size: 12px;
  }
  .subscribe-wrapper .error {
    background-color: #FACDCD;
    padding: 10px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 4px;
    margin-bottom: 4px;
  }
`

class Subscribe extends React.Component {
  constructor() {
    super();
    this.state = {
      email: ``,
      firstName: ``,
    };
  }
  // Update state each time user edits their email address
  _handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };
  // Update state each time user edits their name
  _handleNameChange = e => {
    this.setState({ name: e.target.value });
  };
  // Post to MC server & handle its response
  _postEmailToMailchimp = (email, attributes) => {
    addToMailchimp(email, attributes)
      .then(result => {
        // Mailchimp always returns a 200 response
        // So we check the result for MC errors & failures
        if (result.result !== `success`) {
          this.setState({
            status: `error`,
            msg: result.msg,
          });
        } else {
          // Email address succesfully subcribed to Mailchimp
          this.setState({
            status: `success`,
            msg: result.msg,
          });
        }
      })
      .catch(err => {
        // Network failures, timeouts, etc
        this.setState({
          status: `error`,
          msg: err,
        });
      });
  };
  _handleFormSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.state.email) {
      this.setState({
        status: `error`,
        msg: 'Please enter valid email!',
      });
    } else {
      this.setState({
        status: `sending`,
        msg: null,
      });
      let attributes = { pathname: document.location.pathname };
      if (this.state.name !== undefined) {
        attributes.FNAME = this.state.name;
      }
      // setState callback (subscribe email to MC)
      this._postEmailToMailchimp(this.state.email, attributes);
    }
  };

  render() {
    return (
      <Container>
        {this.state.status === `success` ? (
          <div class="subscribe-wrapper">
            <div class="thanks">
              <div class="title">Thank you!</div>
              <p>Youʼll receive your first email shortly.</p>
            </div>
          </div>
        ) : (
          <div class="subscribe-wrapper">
            <div class="information">
              <div class="title">Enjoyed this post?</div>
              <p>Subscribe and get my latest content by email.</p>
              <div class="icon"><span role="img">📨️</span> <span role="img">📬</span> <span role="img">💌</span></div>
            </div>
            <div class="form">
              <form id="email-capture" method="post" noValidate>
                <div>
                  <input
                    placeholder="Your first name"
                    onChange={this._handleNameChange}
                  />
                  <input
                    placeholder="Your email address"
                    onChange={this._handleEmailChange}
                    required
                  />
                  {this.state.status === `error` && (
                    <div
                      class="error"
                      dangerouslySetInnerHTML={{ __html: this.state.msg }}
                    />
                  )}
                  <button type="submit" onClick={this._handleFormSubmit}>
                    Subscribe
                  </button>
                </div>
                <div class="no-spam">
                  <p>
                    I won’t send you spam.
                    <br />
                    Unsubscribe at <em>any</em> time.
                  </p>
                </div>
              </form>
            </div>
          </div>
        )}
      </Container>
    );
  }
}

export default Subscribe;
